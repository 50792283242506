
/* You can add global styles to this file, and also import other style files */


.breadcrumb {
    //background-color: #eceef3;
    background-color: #f8f8f8;
    cursor: unset !important;
    margin-bottom: 0px;
    background-clip: border-box;
    padding: 0px 30px;
    height: 42px;
    border-radius: 0px;
    box-shadow: 0px 1px 3px rgba(195, 198, 230, 0.74);
    // border-bottom:0.5px solid rgba(186, 203, 225, 0.86);
}

.breadcrumb li:not(:last-child) a {
    color: #187a31;
    font-size: 14px;
}

.breadcrumb li:last-child a {
    color: #595959;
    font-size: 14px;
    cursor: unset !important;
}

.breadcrumb-item+.breadcrumb-item::before {
    color: #707879;
    content: "\f105" !important;
    font-family: "Font Awesome 5 free";
    font-weight: 900;
    position: relative;
    top: 2px;
}

.breadcrumb h4 {
    color: #0b449d;
    font-size: 13px;
    font-weight: 500 !important;
}

.bredcrumb_title_pad>ol>li {
    margin-top: 0.267rem;
}


.carousel-c{
    padding-top: 20px;
}


.product-box-img span img {
    object-fit: cover;
    height: 250px;
}
